.grid-container {
  display: grid;
  grid-template-rows: 60px calc(100vh - 65px);
  height: 100%;
  width: 100%;
  grid-gap: 5px;
  grid-template-areas: 'header' 'main';
}

.header {
  grid-area: header;
  background-color: #fff;
  text-align: center;
  border-bottom: 1px solid #efefef;
}

.header img {
  height: 14px;
  width: auto;
}

.header p {
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 12px;
  margin: 2px 0 0;
}

.main-container {
  grid-area: main;
}

.text-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 7%;
  margin-bottom: 3%;
  width: 50%;
  background-color: transparent;
  color: #333;
  text-align: center;
  padding: 30px;
}

.text-container h3 {
  letter-spacing: 1px;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-weight: 300;
}

@media only screen and (max-width: 1024px) {
  .text-container {
    width: 75%;
  }

  .text-container h3 {
    font-size: 14px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .text-container {
    width: auto;
    padding: 20px;
  }
}

.backgroundImage {
  background-image: url('https://a.suitsupplycdn.com/image/upload/f_auto,q_auto,w_1440/suitsupply/homepage/fw19/week10/HP1-NA-O-wk10.jpg');
  background-size: cover;
}
