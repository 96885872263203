* {
  box-sizing: border-box;
}

html {
  min-height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100vh;
}

.btn-default {
  color: #2d2e2c;
  background-color: #fff;
  border-color: #e8e8e7;
}

.btn {
  font-size: 14px;
  border-radius: 4px;
  padding: 15px 10px;
  cursor: pointer;
  min-width: 125px;
}

a.btn {
  text-decoration: none;
  display: inline-block;
}

.btn:active,
.btn:focus {
  outline: none;
}

.btn-primary {
  color: #fff;
  border-color: #2d2e2c;
  background-color: #2d2e2c;
  box-shadow: 0 0 0 transparent;
}

.btn-primary:disabled {
  background-color: #ccc;
  border-color: #bbb;
  cursor: not-allowed;
}

.btn-default:hover {
  border-color: #2d2e2c;
}